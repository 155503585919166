<script setup lang="ts">
useHead({
  style: ['.el-message {width: initial !important; width: fit-content !important;}'],
})
</script>

<template>
  <defaultHeader />
  <div class="center-container">
    <slot />
  </div>
  <defaultFooter />
</template>

<style lang="scss" scoped>
.center-container {
  background-color: white;
  min-height: calc(100vh - 80px - 162px);
}
</style>
